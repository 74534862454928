
import * as Components from "./Nersses"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "nersses"
}

